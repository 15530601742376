<template>
  <div>
    <p class="mb-3">
      For the
      <stemble-latex :content="element" />
      atom, which ionization energy will be most significantly higher than the one previous to it?
    </p>

    <v-form @submit.prevent="submitResponse">
      <v-radio-group v-model="inputs.input1" class="pl-8" :disabled="!allowEditing">
        <v-radio
          v-for="option in options1"
          :key="option.value"
          class="d-inline-block"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.expression" />
          </template>
        </v-radio>
      </v-radio-group>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'Question200a',
  components: {
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        input1: null,
      },
      options1: [
        {
          expression: '$\\text{IE}_1\\text{ - First Ionization Energy}$',
          value: 'I1',
        },
        {
          expression: '$\\text{IE}_2\\text{ - Second Ionization Energy}$',
          value: 'I2',
        },
        {
          expression: '$\\text{IE}_3\\text{ - Third Ionization Energy}$',
          value: 'I3',
        },
        {
          expression: '$\\text{IE}_4\\text{ - Fourth Ionization Energy}$',
          value: 'I4',
        },
      ],
    };
  },
  computed: {
    versionVariable() {
      return this.taskState.getValueBySymbol('versionVariable').content;
    },
    element() {
      if (this.versionVariable.value === 1) {
        return '$\\textbf{sodium}$';
      } else if (this.versionVariable.value === 2) {
        return '$\\textbf{magnesium}$';
      } else if (this.versionVariable.value === 3) {
        return '$\\textbf{potassium}$';
      } else if (this.versionVariable.value === 4) {
        return '$\\textbf{calcium}$';
      } else if (this.versionVariable.value === 5) {
        return '$\\textbf{rubidium}$';
      } else if (this.versionVariable.value === 6) {
        return '$\\textbf{strontium}$';
      } else {
        return '';
      }
    },
  },
};
</script>
